.toast {
    width: fit-content;
    max-width: 1000px;
    max-height: fit-content;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    
    z-index: 999999999;
}

.toast-container {
    padding: 0;
    background: transparent;
    max-height: fit-content;
    border: none;
    box-shadow: none;
    
}

.toast-container button{
    display: none;
}

.toast-body{
    padding: 0;
    max-height: fit-content;
    border: none;
    background: transparent;
    box-shadow: none;
    
}

.toast-progress{
    background: inherit;
}